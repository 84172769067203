import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';
import { QuestionItemProps } from './model';

export const QuestionItem = forwardRef<HTMLDivElement, StylableProp<QuestionItemProps>>(
  (props, ref) => {
    return (
      <div ref={ref} className={style.questionWrapper + ' d-flex flex-column align-items-start'}>
        <p className={style.question + ' d-flex align-items-center user-select-none'}>
          {props.question.text}
        </p>
      </div>
    );
  }
);

export const MotionQuestionItem = motion(QuestionItem);
export default MotionQuestionItem;
