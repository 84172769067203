import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { AvatarProps } from './model';
import * as style from './index.module.scss';
import { StylableProp } from '../../utils/stylable-prop';

export default function Avatar(props: StylableProp<AvatarProps>) {
  const image = getImage(props.file);
  return (
    <GatsbyImage className={`${style.avatar} ${props.className}`} image={image!} alt={props.alt} />
  );
}
