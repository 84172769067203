import React from 'react';
import Layout from '../components/layout';
import Questionnaire from '../components/questionnaire';
import { Question } from '../components/questionnaire/model';

export default function ApplyPage() {
  const questions: Question[] = [
    {
      text: `Hey, I'm Katie, your Nuborrow Guide.`
    },
    {
      text: `Let's start with how i can have our team at Nuborrow help you.`
    },
    {
      text: `Amazing! Are you currently working with anyone at Nuborrow?`
    },
    {
      text: `Let's add your name to open up your file.`
    },
    {
      text: `Added! Now some quick contact details.`
    },
    {
      text: `When is your birthday?`
    }
  ];

  return (
    <Layout pageTitle="Apply">
      <Questionnaire questions={questions} />
    </Layout>
  );
}
