import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { KatieAvatarQuery } from '../../../graphql-types';
import { StylableProp } from '../../utils/stylable-prop';
import Avatar from '../avatar';
import * as style from './index.module.scss';
import { QuestionnaireProps } from './model';
import MotionQuestionItem from './question-item';

export default function Questionnaire(props: StylableProp<QuestionnaireProps>) {
  const [questions, setQuestions] = useState([props.questions[0]]);
  const avatarImg = useStaticQuery<KatieAvatarQuery>(dataQuery);

  return (
    <section
      className={`${style.questionnaire} d-block overflow-hidden position-relative m-auto ${props.className}`}
    >
      <div className="container position-relative h-100">
        <div className={style.avatar}>
          <Avatar
            className={style.avatarImage}
            key={avatarImg.file?.relativePath}
            file={avatarImg.file as unknown as ImageDataLike}
            alt={'Katie'}
          />
        </div>
        <ul className={style.questionList}>
          {questions.map((question, index) => (
            <MotionQuestionItem key={`question-${index}`} question={question} />
          ))}
        </ul>
      </div>
      <button onClick={() => setQuestions(props.questions.slice(0, questions.length + 1))}>
        Add
      </button>
      <button
        onClick={() =>
          setQuestions(props.questions.slice(0, questions.length < 2 ? 1 : questions.length - 1))
        }
      >
        Remove
      </button>
    </section>
  );
}

const dataQuery = graphql`
  query KatieAvatar {
    file(relativePath: { eq: "person-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 64, height: 64, layout: CONSTRAINED, placeholder: BLURRED)
      }
      relativePath
    }
  }
`;
